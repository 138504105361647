import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Subtitle, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Suivi communautaire</Hero>
    <Container text>
      <Paragraph>
        Transition Centre-Sud chapeaute le programme de suivi en communauté La
        Passerelle. Celui-ci s’adresse à la clientèle devant respecter un suivi
        en communauté dans le cadre d’une ordonnance de probation avec
        surveillance ou d’un emprisonnement avec sursis. De plus, les individus
        ayant séjourné à La Maison L’Issue, en permission de sortie préparatoire
        à la libération conditionnelle ou en libération conditionnelle, peuvent
        y compléter leur suivi dans une vision de continuité de l’intervention.
      </Paragraph>

      <Paragraph>
        De façon générale, La Passerelle s’assure du respect légal des
        conditions de remise en liberté et aide à outiller la personne
        judiciarisée afin qu’elle évite d’avoir à nouveau recours à la
        criminalité. La prévention de la récidive permet également de travailler
        les concepts de : gestion du risque, réhabilitation, réinsertion
        sociale, éducation, responsabilisation, développement d’habiletés
        sociales, atteinte de l’autonomie, etc…
      </Paragraph>
      <Paragraph>
        À l’aide des outils disponibles (LS/CMI, évaluation d’admission,
        expertise…), les professionnels de La Passerelle élaborent une stratégie
        d’intervention individualisée qui inclut la gestion du risque, le
        développement d’habiletés sociales, l’éducation et l’atteinte de
        l’autonomie.
      </Paragraph>
      <Paragraph>
        Plus particulièrement, La Passerelle vise à ce que le contrevenant
        entame une prise de conscience de sa situation et qu’il travaille
        activement à l’atteinte des objectifs du plan d’intervention. En effet,
        pour démarrer un travail efficace et modifier des facettes de sa vie, il
        doit avant tout prendre conscience de sa situation légale, et surtout,
        prendre conscience des problèmes qui l’ont amené jusqu’à nous et des
        aspects à travailler.
      </Paragraph>
      <Paragraph>
        On s’attend à ce que chaque client s’engage dans une démarche
        d’activation et de responsabilisation en lien avec ses choix, ses
        actions et les problématiques ayant précipité son agir délictuel. Il est
        le principal acteur de son cheminement. Les professionnels de La
        Passerelle ne peuvent que l’orienter, l’encadrer, l’accompagner et le
        supporter dans ses décisions et démarches.
      </Paragraph>
      <Paragraph>
        La Passerelle assure le suivi en communauté d’une clientèle adulte
        masculine qui lui est référée par le réseau correctionnel québécois.
        Suite à une période de conscientisation et après évaluation de l’agent
        de probation, le dossier classé « réinsertion sociale » est transféré à
        un intervenant communautaire. Ce dernier prend la relève du suivi et
        assure l’accompagnement et l’encadrement de la clientèle.
      </Paragraph>
      <Paragraph>
        La clientèle ayant une problématique de violence conjugale peut
        bénéficier de l’expertise de la ressource. Il n’y a aucun critère
        d’exclusion, outre l’exigence que le lieu de résidence soit sur l’île de
        Montréal.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
