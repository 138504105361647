import React from "react";
import styled from "styled-components";

import { Container } from "semantic-ui-react";
import { Paragraph, Hero, Subtitle, Content } from "../../layout";

const Image = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url(/images/principes-directeurs.jpg) center center no-repeat;
  background-size: cover;
  height: 350px;
  margin: 1.5em 0;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(3, 27, 78, 0.05);
`;

const PrincipesDirecteurs = () => (
  <Content>
    <Hero>Principes directeurs</Hero>
    <Container text>
      <Paragraph>
        Au fil des ans, Transition Centre-Sud a su développer sa propre culture
        et sa personnalité. Ainsi, l’intervention qui y est offerte est basée
        sur certains principes bien campés qui guident les intervenants et la
        direction dans leur façon d’être et d’agir avec la clientèle.
      </Paragraph>
      <Image />

      <Subtitle as="h2">Prévention de la récidive</Subtitle>
      <Paragraph>
        La prévention de la récidive, incluant la gestion du risque, teinte
        l’ensemble du programme de Transition Centre-Sud. Considérant la gravité
        objective des gestes posés par chaque usager, la prévention de la
        récidive et la gestion du risque guident l’intervention à préconiser.
      </Paragraph>

      <Subtitle as="h2">Individualisation</Subtitle>
      <Paragraph>
        Malgré un fonctionnement global semblable pour tous, chaque usager pris
        en charge par l’équipe de professionnels bénéficie d’un suivi
        individualisé qui tient compte de ses lacunes, de ses problèmes, des
        problématiques, mais également des forces qui lui sont propres.
      </Paragraph>

      <Subtitle as="h2">Responsabilité et autonomie</Subtitle>
      <Paragraph>
        On s’attend à ce que chaque usager s’engage dans une démarche
        d’activation et de responsabilisation en lien avec ses choix, ses
        actions et les problématiques ayant précipitées son agir délictuel. Il
        est le principal acteur de son cheminement. Les professionnels de
        Transition Centre-Sud ne peuvent que l’orienter, l’encadrer,
        l’accompagner et le supporter dans ses décisions et démarches.
      </Paragraph>

      <Subtitle as="h2">Cohésion, cohérence et uniformité</Subtitle>
      <Paragraph>
        La cohésion, la cohérence et l’uniformité occupent également une place
        importante dans la philosophie d’intervention de La Maison L’Issue.
        Ainsi, l’équipe clinique se doit d’être unie et les interventions posées
        doivent être logiques et cohérentes, tant au niveau du fonctionnement à
        l’interne qu’envers les attentes des services correctionnels et les
        besoins du client.
      </Paragraph>

      <Subtitle as="h2">Encadrement</Subtitle>
      <Paragraph>
        Transition Centre-Sud encadre, surveille et soutient la clientèle qui
        lui est référée par les services correctionnels. Pour La Maison L’Issue,
        les règlements contenus dans le « Guide du résident » et les
        interventions des professionnels permettent d’orienter quotidiennement
        le résident et de le supporter dans ses démarches.
      </Paragraph>

      <Subtitle as="h2">Autorité</Subtitle>
      <Paragraph>
        Parce que Transition Centre-Sud travaille en partenariat avec les
        services correctionnels, elle a nécessairement, selon la « Loi sur le
        système correctionnel du Québec » un lien d’autorité avec la clientèle.
        Ce lien est clairement défini dans le « Manuel d’application des mesures
        de suivi dans la communauté ».
      </Paragraph>

      <Subtitle as="h2">Exigences</Subtitle>
      <Paragraph>
        Le plan d’intervention correctionnel guide les interventions à faire
        avec la clientèle. Toutefois, si des besoins, des problématiques ou des
        démarches spécifiques en liens avec les besoins criminogènes sont ciblés
        par le conseiller et ne se retrouvent pas au PIC, ce dernier peut être
        révisé (avec l’accord de l’agent de probation assigné au dossier).
        Autrement, pour les autres besoins non criminogènes, un plan
        d’intervention maison peut être élaboré. Celui-ci est construit après
        réflexion, analyse, discussion et entente entre l’usager et le
        conseiller. La prise de conscience par le résident lui-même est
        souhaitable, mais vient un moment où l’intervenant doit exiger des
        actions pour espérer des résultats.
      </Paragraph>

      <Subtitle as="h2">Limites et priorités</Subtitle>
      <Paragraph>
        Dans le contexte de partenariat avec les services correctionnels,
        l’intervention envers le client doit tenir compte d’une certaine réalité
        (mesures légales, niveau d’éducation, durée du suivi, fréquence des
        entrevues, nature et ampleur de la problématique, objectifs du plan
        d’intervention correctionnel). Il nous apparaît souhaitable de bien
        cerner les interventions à prioriser pour maximiser l’impact et
        l’efficacité de l’énergie déployée (à court terme) et amener des
        changements concrets et durables (à moyens ou longs termes).
      </Paragraph>

      <Subtitle as="h2">Référence</Subtitle>
      <Paragraph>
        Pour répondre à des besoins spécifiques (recherche d’emploi, gestion du
        budget, toxicomanie, délinquance sexuelle…), la référence se fait
        systématiquement vers des ressources spécialisées dans la communauté.
      </Paragraph>

      <Subtitle as="h2">Non-personnalisation / Éthique</Subtitle>
      <Paragraph>
        La relation entre l’usager et l’employé à Transition Centre-Sud est
        celle du client à l’intervenant. Aucun autre lien n’est considéré. Sans
        enlever le caractère humain qui existe entre tous ceux qui fréquentent
        la ressource, chacun doit connaître son rôle et l’assumer. Ainsi,
        l’intervention auprès de la clientèle ne peut être influencée par des
        facteurs d’ordre économique, émotif ou personnel.
      </Paragraph>

      <Subtitle as="h2">Éducation</Subtitle>
      <Paragraph>
        Les employés de Transition Centre-Sud tirent avantage de toutes les
        occasions qui se présentent pour promouvoir les valeurs positives. Ils
        agissent à titre de catalyseurs auprès de la clientèle. Cette
        intervention se fait au quotidien à travers différentes sphères. Elle se
        définit entre autres par l’apprentissage de saines habitudes de vie,
        d’aptitudes sociales, d’un équilibre des sphères, du maintien et respect
        des lieux, d’une hygiène personnelle, d’une planification des
        rendez-vous et des activités, de l’élaboration et de la gestion d’un
        budget.
      </Paragraph>

      <Subtitle as="h2">Accueil et respect</Subtitle>
      <Paragraph>
        À Transition Centre-Sud, les employés s’assurent d’accueillir et
        d’intervenir auprès de l’usager avec ouverture et respect. Ainsi, grâce
        à son approche et à ses valeurs humaines, le respect de l’autorité, des
        lois, des personnes, des choses font partit des principes démontrés ou
        défendus par tous ceux qui sont en contact avec la clientèle.
      </Paragraph>
    </Container>
  </Content>
);

export default PrincipesDirecteurs;
