import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Centre d’hébergement communautaire</Hero>
    <Container text>
      <Paragraph>
        Le mandat de ce programme est d’offrir à la clientèle fédérale un
        hébergement de dépannage temporaire et de courte durée. Toutefois,
        l’intervention offerte à La Maison L’Issue va au-delà de l’intervention
        minimale attendue par son mandat de CHC, compte tenu de l’orientation
        première de la Maison L’Issue ; c’est-à-dire un centre résidentiel
        communautaire provincial. Ainsi, il est attendu que le résident sous
        juridiction fédérale respecte le cadre et les règlements de la
        ressource, comme tous les autres résidents de la ressource.
      </Paragraph>

      <Paragraph>
        Le résident soumis à une libération d’office qui vient temporairement
        habiter à La Maison L’Issue intègre un milieu de vie structuré et
        supervisé qui lui permettra de combler ses besoins de base (logé et
        nourri), tout en bénéficiant :
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            D’une surveillance qui consiste à intervenir auprès de la clientèle
            sur le respect des autres, du milieu de vie et des règlements de la
            ressource ainsi que sur l’importance du respect des conditions
            légales et des objectifs du plan d’intervention correctionnel. Selon
            la nature et la gravité des événements observés, l’agent de
            libération conditionnelle ou le Centre national de surveillance en
            sera informé dans les délais prescrits.
          </li>
          <li>
            D’un soutien donné par les conseillers cliniques et intervenants
            professionnels de la Maison L’Issue qui s’offre sous la forme
            d’écoute active, de conseils et de références et qui vise à
            alimenter la motivation des résidents vers le développement de
            l’autonomie et l’atteinte des objectifs de leur plan d’intervention
            correctionnel respectif.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        Ces mesures sont mises en place afin de faciliter le retour en
        communauté de l’individu. Bien qu’il soit jumelé à un intervenant de La
        Maison L’Issue qui portera une attention particulière mais limitée à son
        cheminement, le résident en CHC doit s’adresser à son agent de
        libération conditionnelle des SCC pour son suivi légal et clinique ainsi
        que pour obtenir certaines permissions. La Maison L’Issue gardera un
        contact régulier, voire quotidien, avec l’ALC afin de l’aviser des
        démarches effectuées par le résident, de son fonctionnement ou de toute
        autre situation sensible.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
