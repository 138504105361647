import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Programme court</Hero>
    <Container text>
      <Paragraph>
        La Maison L’Issue propose un programme court qui s’adresse aux
        contrevenants servant une courte sentence (moins de 6 mois).
      </Paragraph>
      <Paragraph>
        L’objectif de prévention de la récidive demeure la pierre angulaire de
        l’intervention à L’Issue, mais les modalités pour y travailler seront
        prioritairement d’ordre organisationnel et structurel avec un
        encadrement et une supervision quotidienne des démarches réalisées ;
        compte tenu de la courte durée du séjour.
      </Paragraph>
      <Paragraph>
        Outre les démarches pour récupérer les cartes d’identité, l’inscription
        à la Solidarité sociale ou la recherche d’emploi, la participation à des
        groupes de soutien et les démarches thérapeutiques, ces résidents seront
        orientés vers les ressources adaptées pour y acheter à prix réduit des
        vêtements et les bottes de travail nécessaires à la plupart des emplois
        dans les agences de placement où ils seront prioritairement référés.
      </Paragraph>
      <Paragraph>
        La recherche de logement (appartement supervisé, colocation, etc.) et le
        maintien de l’abstinence complèteront les activités visées par ce court
        séjour, de façon à ce qu’une stabilité et une autonomie de base soient
        acquises au 2/3 de la sentence.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
