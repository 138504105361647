import React from "react";
import styled from "styled-components";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Image,
  Menu,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  background-color: #ffffff;
`;

const LogoWrapper = styled.div`
  position: relative;
  margin: 2em 0;
`;

const Link = styled(NavLink)`
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  display: block;
  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
`;

const DropdownLink = styled(NavLink)`
  color: #282828;
  font-size: 1.2em;
  display: block;
  padding: 0.7em 2em 0.7em 1em;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #282828;
  }
`;

const NavWrapper = styled.div`
  position: relative;
  color: #7d7c83;
  font-weight: bold;
  background: #002035;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

const DropdownMenu = ({ label, baseUrl, items = [] }) => (
  <Dropdown icon="angle down" item text={label}>
    <Dropdown.Menu>
      {items.map(({ label, path }, i) => (
        <Dropdown.Item key={i}>
          <DropdownLink to={`${baseUrl}/${path}`}>{label}</DropdownLink>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const LinkItem = ({ label, path, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Menu.Item>
      <Link to={path}>{label}</Link>
    </Menu.Item>
  );
};

const Item = ({ items, path, ...rest }) => (
  <>
    {!items.length ? (
      <LinkItem path={path} {...rest} />
    ) : (
      <DropdownMenu items={items} baseUrl={path} {...rest} />
    )}
  </>
);

const Navigation = ({ items = [] }) => (
  <Wrapper>
    <Container>
      <LogoWrapper>
        <Grid verticalAlign="middle">
          <Grid.Column width={8}>
            <Link to={{ pathname: "/" }} style={{ display: "inline" }}>
              <Image src="/images/transition-centre-sud.png" inline />
            </Link>
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <NavLink to="/contact">
              <Button color="yellow" size="large">
                Nous contacter
              </Button>
            </NavLink>
          </Grid.Column>
        </Grid>
      </LogoWrapper>
    </Container>
    <NavWrapper>
      <Container>
        <Menu position="right" borderless>
          {items.map(({ items = [], ...props }, i) => (
            <Item key={i} {...props} items={items} />
          ))}
        </Menu>
      </Container>
    </NavWrapper>
  </Wrapper>
);

export default Navigation;
