import React from "react";
import styled from "styled-components";
import { Container, Header, List, Image, Grid } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../layout";

const Wrapper = styled.div`
  margin-top: 6em;
`;

const Map = () => {
  const frameborder = "0";
  const style = { border: 0 };
  const [width, height] = ["100%", 600];
  const src =
    "https://www.google.com/maps/embed/v1/place?key=AIzaSyCDswghtxD5fCUNTV3SbuzbNTchNIsoVUw&q=Transition+Centre-Sud,1819+boulevard+Rosemont";
  return (
    <iframe
      width={width}
      height={height}
      src={src}
      frameborder={frameborder}
      style={style}
      allowfullscreen
    ></iframe>
  );
};

const Page = () => (
  <Content>
    <Hero>Nous contacter</Hero>
    <Container text>
      <Grid padded={false} stackable>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h3" content="Addresse" />
            <List link>
              <List.Item>1819, boul. Rosemont</List.Item>
              <List.Item>Montréal, Québec</List.Item>
              <List.Item>H2G 1S5</List.Item>
            </List>

            <Header as="h3" content="Contacts" />
            <List link>
              <List.Item>
                <List.Icon color="yellow" name="phone" />
                <List.Content>514-270-6633</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon color="yellow" name="fax" />
                <List.Content>514-270-2799</List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="bottom" textAlign="right">
            <Image rounded src="/images/nous-contacter.jpg" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <Wrapper>
      <Container text>
        <Paragraph align="center">
          Station de métro Rosemont (bus 197-E) ou Fabre (bus 45-S)
        </Paragraph>
        <Map />
      </Container>
    </Wrapper>
  </Content>
);

export default Page;
