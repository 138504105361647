import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Programme Point Final.</Hero>
    <Container text>
      <Paragraph>
        Mis sur pied en collaboration avec l’organisme OPTION, l’objectif du
        programme vise la prévention de la récidive, la responsabilisation et le
        développement de compétences et de stratégies pour contrer la violence
        chez une clientèle ayant besoin d’investissement quotidien et d’un
        encadrement rigoureux.
      </Paragraph>

      <Paragraph>
        Le programme Point Final. offre à ses participants un cadre de réflexion
        et d’apprentissage encadrant et sécuritaire, en leur permettant de
        travailler leurs lacunes auxquelles ils se sont heurtés dans leurs
        relations avec leurs partenaires afin d’éviter la récidive tout en
        respectant des conditions strictes de remise en liberté. Il s’agit d’un
        programme multiaxial jumelant l’encadrement légal et clinique et qui
        cible des habiletés « saines » et « normalisantes » sur différents plans
        tels la communication, la gestion d’une crise conjugale, la dépendance
        affective, la gestion de la colère, l’affirmation de soi, le contrôle
        émotionnel, les habiletés parentales, le contrôle de la consommation de
        substances et les saines habitudes de vie. Ce programme s’inscrit à
        l’intérieur du cadre normatif des exigences minimales de conformité des
        SCQ.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
