import { HomePage, ContactPage } from "../components/pages";
import {
  HistoriquePage,
  MissionPage,
  RaisonDetrePage,
  StatistiquesAnnuellesPage,
  PrincipesDirecteursPage,
} from "../components/pages/a-propos";
import {
  BenevolatPage,
  CAPage,
  OffresDemploiPage,
  StagesPage,
} from "../components/pages/carrieres-benevolat";
import {
  AteliersPage,
  PasserellePointFinalPage,
  PortesOuvertesPage,
  SuiviCommunautairePage,
} from "../components/pages/la-passerelle";
import {
  CHCPage,
  CRCPage,
  DevenirResidentPage,
  GuideResidentPage,
  IssuePointFinalPage,
  ProgrammeCourtPage,
  TemoignagesPage,
} from "../components/pages/maison-lissue";

import {
  PartenairesPage,
  RessourcesPage,
} from "../components/pages/ressources-partenaires";

const flattenRoutes = (routes) => {
  return routes.reduce((r, { path: basePath, items = [], ...rest }) => {
    if (items.length) {
      items.forEach(({ path, ...rest }) => {
        r.push({
          ...rest,
          path: `${basePath}/${path}`,
        });
      });
    } else {
      r.push({
        ...rest,
        path: basePath,
      });
    }
    return r;
  }, []);
};

const routes = {
  items: [
    {
      label: "Accueil",
      path: "/",
      component: HomePage,
    },
    {
      label: "À propos de nous",
      path: "/a-propos-de-nous",
      items: [
        {
          label: "Historique",
          path: "historique",
          component: HistoriquePage,
        },
        {
          label: "Mission",
          path: "mission",
          component: MissionPage,
        },
        {
          label: "Raison d’être",
          path: "raison-detre",
          component: RaisonDetrePage,
        },
        {
          label: "Principes directeurs",
          path: "principes-directeurs",
          component: PrincipesDirecteursPage,
        },
        // {
        //   label: "Statistiques annuelles",
        //   path: "statistiques-annuelles",
        //   component: StatistiquesAnnuellesPage,
        // },
      ],
    },
    {
      label: "Maison L'issue",
      path: "/maison-l-issue",
      items: [
        {
          label: "Centre résidentiel communautaire",
          path: "crc",
          component: CRCPage,
        },
        {
          label: "Centre d’hébergement communautaire",
          path: "chc",
          component: CHCPage,
        },
        {
          label: "Programme Point Final.",
          path: "programme-point-final",
          component: IssuePointFinalPage,
        },
        {
          label: "Programme court",
          path: "programme-court",
          component: ProgrammeCourtPage,
        },
        {
          label: "Devenir résident",
          path: "devenir-resident",
          component: DevenirResidentPage,
        },
        // {
        //   label: "Guide du résident",
        //   path: "guide-du-resident",
        //   component: GuideResidentPage,
        // },
        // {
        //   label: "Témoignages",
        //   path: "temoignages",
        //   component: TemoignagesPage,
        // },
      ],
    },
    {
      label: "La Passerelle",
      path: "/la-passerelle",
      items: [
        {
          label: "Suivi communautaire",
          path: "suivi-communautaire",
          component: SuiviCommunautairePage,
        },
        {
          label: "Programme Point Final.",
          path: "programme-point-final",
          component: PasserellePointFinalPage,
        },
        {
          label: "Ateliers",
          path: "ateliers",
          component: AteliersPage,
        },
        {
          label: "Portes ouvertes",
          path: "portes-ouvertes",
          component: PortesOuvertesPage,
        },
      ],
    },
    {
      label: "Carrières et bénévolat",
      path: "/carrieres-et-benevolat",
      items: [
        {
          label: "Conseil d’administration",
          path: "conseil-dadministration",
          component: CAPage,
        },
        {
          label: "Bénévolat",
          path: "benevolat",
          component: BenevolatPage,
        },
        {
          label: "Stages",
          path: "stages",
          component: StagesPage,
        },
        // {
        //   label: "Offres d’emploi",
        //   path: "offres-demploi",
        //   component: OffresDemploiPage,
        // },
      ],
    },
    // {
    //   label: "Ressources et partenaires",
    //   path: "/ressources-et-partenaires",
    //   component: RessourcesPage
    // },
    {
      label: "Nous contacter",
      path: "/contact",
      isVisible: false,
      component: ContactPage,
    },
  ],
};

export { flattenRoutes };
export default routes;
