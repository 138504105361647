import React from "react";
import styled from "styled-components";
import { Container, Segment, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const HistoriqueImg = styled(Image)`
  border-radius: 5px;
  margin-top: 1.2em !important;
  margin-left: 1.5em !important;
  width: 400px;
`;

const HistoriquePage = () => (
  <Content>
    <Hero>Historique</Hero>
    <Container text>
      <HistoriqueImg src="/images/historique.jpg" floated="right" />
      <Paragraph>
        La corporation Transition Centre-Sud (TCS) est née en 1983 de la volonté
        de citoyens et intervenants d’offrir l’opportunité à certains prévenus
        d’éviter l’incarcération durant le déroulement de leur procès.
      </Paragraph>
      <Paragraph>
        Le conseil d’administration provisoire a donc mis sur pied la
        corporation après avoir convaincu la magistrature et les SCQ de son
        bien-fondé. Au départ, il avait été envisagé d’installer le centre
        d’hébergement dans le quartier Centre-Sud; c’est pourquoi la corporation
        a été baptisée Transition Centre-Sud. Puisque la recherche d’un bâtiment
        adapté à ce genre d’activité a été plus difficile que prévu, c’est dans
        le quartier Rosemont, La-Petite-Patrie que L’Issue s’est installée en
        1984. Le premier résidant est arrivé en septembre.
      </Paragraph>
      <Paragraph>
        À la fin des années 1990, devant l’hésitation des SCQ à maintenir le
        financement des séjours de prévenus, L’Issue a diversifié sa clientèle
        en accueillant des détenus au sein de ce qui devenait un Centre
        résidentiel communautaire (CRC). La Corporation Transition Centre-Sud
        était de plus sollicitée pour faire le suivi d’une clientèle externe.
        TCS développa donc La Passerelle, son programme de Suivi en communauté.
      </Paragraph>
      <Paragraph>
        En 1997, les employés de TCS ont choisi de se syndiquer.
      </Paragraph>
      <Paragraph>
        En 2003, les SCQ ont mis définitivement fin au financement des séjours
        des prévenus. Depuis, les contrats d’hébergement pour la maison de
        transition L’Issue ne sont accordés qu’exclusivement aux personnes
        sentenciés.
      </Paragraph>
      <Paragraph>
        En 2006, en vue de l’application de la nouvelle loi sur le Système
        correctionnel qui entrait en vigueur en février 2007, les SCQ ont
        sollicité des organismes communautaires, dont les maisons de transition,
        pour faire le suivi dans la communauté de la majorité des contrevenants
        en probation, en sursis ou en libération conditionnelle. TCS a signé un
        contrat pour faire le suivi continu d’usagers dans la communauté.
      </Paragraph>
      <Paragraph>
        En 2012, face aux difficultés financières persistantes, dû à un sous
        financement et confronté à la baisse de la référence provinciale, TCS a
        accepté un contrat de « foyer » fédéral pour cinq détenus fédéraux qui
        sortent d’un pénitencier sans avoir d’assignation à une maison de
        transition mais qui ont besoin d’une résidence durant un mois ou deux,
        le temps d’organiser leur sortie formelle. La même année, TCS a aussi
        élaboré, en collaboration avec l’organisme « Option, une alternative à
        la violence conjugale et familiale » un programme pour usagers condamnés
        pour violence conjugale (Point Final.) et un programme pour courts
        séjours (sentences de moins de six mois).
      </Paragraph>
      <Paragraph>
        En 2018, des changements importants ont lieu au sein de la direction, du
        conseil d’administration ainsi qu’au niveau des employés. De nouveaux
        projets sont envisagés et qui seront concrétisés, espérons-le, au
        courant des prochains mois et des prochaines années…
      </Paragraph>
    </Container>
  </Content>
);

export default HistoriquePage;
