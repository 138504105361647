import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Ateliers « Introduction au suivi en communauté »</Hero>
    <Container text>
      <Paragraph>
        La clientèle étant référée à La Passerelle bénéficiera des ateliers d’«
        Introduction en suivi en communauté » suite à sa prise en charge. Les
        objectifs visés par les ateliers sont les mêmes que ceux visés lors des
        rencontres individuelles, c’est-à-dire :
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            Faire diminuer les résistances face au suivi communautaire et
            augmenter l’ouverture et la participation lors des rencontres.
          </li>
          <li>
            Stimuler la motivation, l’implication face au suivi communautaire
            ainsi que l’activation autonome face aux besoins criminogènes
            identifiés au plan d’intervention correctionnel.
          </li>
          <li>
            Poursuivre le travail de réflexion et de responsabilisation initié
            avant le transfert au communautaire (durant la période de
            conscientisation) afin de provoquer des changements plus rapide.
          </li>
        </ul>
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
