import React from "react";
import styled from "styled-components";

import { Container, Image } from "semantic-ui-react";
import { Paragraph, Hero, Subtitle, Content } from "../../layout";

const Testee = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url(/images/devenir-resident-1.jpg) center center no-repeat;
  background-size: cover;
  height: 400px;
  margin: 1.5em 0;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(3, 27, 78, 0.15);
`;

const Page = () => (
  <Content>
    <Hero>Devenir résident</Hero>
    <Container text>
      <Subtitle as="h2">CRC (provincial) :</Subtitle>
      <Paragraph>
        L’Issue accueille une clientèle adulte masculine qui lui est référée par
        le réseau correctionnel québécois. Chaque candidat doit se soumettre à
        une évaluation et satisfaire aux critères suivants :{" "}
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            Il doit reconnaître minimalement une problématique en lien avec le
            délit.
          </li>
          <li>
            Il doit être déterminé à investir les symptômes sous-jacents à la
            problématique dans une optique de prévention de la récidive.
          </li>
          <li>
            Il doit être prêt à collaborer et à s’investir dans un processus de
            changement.
          </li>
          <li>
            Il doit accepter de s’investir au besoin dans un suivi thérapeutique
            spécialisé en lien avec une problématique ciblée et en défrayer les
            coûts.
          </li>
          <li>
            Il doit accepter de s’investir dans son plan d’intervention
            correctionnel.
          </li>
          <li>
            Il doit accepter de se soumettre à un encadrement intensif
            (intervention 24 heures/24) toute la durée du séjour.
          </li>
          <li>
            Il doit être prêt à vivre dans un milieu communautaire qui offre peu
            d'intimité (chambres partagées, encadrement rigoureux).
          </li>
          <li>
            Il doit avoir la capacité de s'impliquer positivement dans la
            communauté (travail, études, bénévolat).
          </li>
          <li>Il doit être capable d'être sobre et abstinent en communauté.</li>
          <li>
            Il ne doit pas représenter un risque inassumable pour la société.
          </li>
          <li>Il doit parler et comprendre le français ou l’anglais.</li>
        </ul>
      </Paragraph>
      <Paragraph>
        Aucun candidat n’est refusé sur la base de la gravité du délit.
      </Paragraph>
      <Testee />


      <Subtitle as="h2">Procédure de sélection</Subtitle>
      <Paragraph>
        À partir de la détention, l’agent titulaire au dossier transmet au CRC
        tous les documents nécessaires à l’évaluation du candidat. Suite à la
        lecture des documents, l’agent de liaison du CRC fera une entrevue avec
        le candidat en personne ou par téléphone. Les éléments suivants sont
        investigués :
      </Paragraph>

      <Paragraph>
        <ul>
          <li>La réflexion par rapport à la criminalité</li>
          <li>L’historique criminel</li>
          <li>Le déroulement de la sentence actuelle</li>
          <li>Les problématiques criminogènes</li>
          <li>L’évaluation du risque représenté</li>
          <li>Le plan de sortie et la motivation à intégrer un CRC</li>
          <li>
            Toute autre problématique spécifique relevée lors de l’évaluation
          </li>
        </ul>
      </Paragraph>

      <Image src="/images/devenir-resident-2.jpg" height="400px" />

      <Subtitle as="h2">CHC (fédéral) :</Subtitle>
      <Paragraph>
        La référence passe par le biais de l’agent de libération conditionnelle
        du Bureau Maisonneuve, qui assure la liaison entre le service
        correctionnel canadien et La Maison L’Issue. Le CHC accueille des
        candidats qui bénéficient d’une libération d’office (élargissement au
        2/3 de la sentence) et qui nécessitent une période d’hébergement avec
        une surveillance supplémentaire à un foyer de dépannage.{" "}
      </Paragraph>
      <Paragraph>
        La procédure de sélection consiste à la transmission des documents
        relatifs à l’évaluation du candidat entre les deux parties. Suite à la
        lecture des informations contenues dans le dossier, l’agent de liaison
        de L’Issue évalue sur papier l’admissibilité du candidat. Les éléments
        suivants sont investigués :
      </Paragraph>
      <Paragraph>
        <ul>
          <li>La reconnaissance du délit et des problématiques</li>
          <li>La motivation à les travailler</li>
          <li>La capacité de gérer le risque si encadré</li>
        </ul>
      </Paragraph>

      <Paragraph>
        De plus, le candidat doit s’engager à respecter le code de vie et les
        règlements de La Maison L’Issue.
      </Paragraph>
      <Subtitle as="h2">EXCLUSIONS pour tous les programmes:</Subtitle>
      <Paragraph>
        <ul>
          <li>Clientèle psychiatrisée, instable et non médicamentée</li>
          <li>Handicapé physique pour lequel les lieux ne sont pas adaptés</li>
          <li>Déficience intellectuelle moyenne ou profonde</li>
          <li>Contrevenant dont le complice réside à La Maison L’Issue</li>
          <li>Clientèle allophone</li>
        </ul>
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
