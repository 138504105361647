import styled from "styled-components";

import Navigation from "./Navigation";
import { Hero, Subtitle, Title } from "./Hero";
import Footer from "./Footer";

const Paragraph = styled.p`
  text-align: ${(props) => props.align || "justify"};
  font-size: 1.2rem;
  padding: 0.25em 0;
  color: #676767;
`;

const Content = styled.div`
  padding: 0 0 8em;
  background: #f9fafb;
  min-height: 600px;
`;

export { Paragraph, Content, Hero, Subtitle, Title, Navigation, Footer };
