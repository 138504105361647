import React from "react";
import styled from "styled-components";
import { Container, Header, List, Segment, Grid } from "semantic-ui-react";

const Wrapper = styled.div`
  color: #fff;
  width: 100%;
  background: #002035;
`;

const InfoWrapper = styled(Container)`
  padding: 1em 0;
`;

const Copyright = styled.p`
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 0.9em;
  line-height: 40px;
`;

const Email = styled.a`
  color: #000 !important;
  &:hover {
    color: rgba(255, 255, 255, 0.9) !important;
  }
`;

const ListContent = styled(List.Content)`
  color: #fff;
`;

const Footer = () => (
  <Wrapper>
    <InfoWrapper>
      <Grid relaxed padded stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            <Header inverted as="h4" content="Transition Centre-Sud" />
            <List inverted link>
              <List.Item>1819, boul. Rosemont</List.Item>
              <List.Item>Montréal, Québec</List.Item>
              <List.Item>H2G 1S5</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="bottom">
            <List inverted link>
              <List.Item>
                <List.Icon color="yellow" name="phone" />
                <ListContent>514-270-6633</ListContent>
              </List.Item>
              <List.Item>
                <List.Icon color="yellow" name="fax" />
                <ListContent>514-270-2799</ListContent>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InfoWrapper>

    <Copyright>
      <Container>© Tous droits réservés 2020. Transition Centre-Sud</Container>
    </Copyright>
  </Wrapper>
);

export default Footer;
