import React from "react";
import styled from "styled-components";
import { Container, Header } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Image = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url(/images/raison-detre.png) center center no-repeat;
  background-size: cover;
  height: 440px;
  margin: 1.5em 0;
  border-radius: 5px;
  text-align: center;
`;

const RaisonDetrePage = () => (
  <Content>
    <Hero>Raison d’être</Hero>
    <Container text>
      <Paragraph>
        La Maison L’Issue est un centre résidentiel communautaire (CRC) pour les
        individus en permission de sortie préparatoire à la libération
        conditionnelle (SPLC), en libération conditionnelle (LC) ou en
        permission de sortir accordée par l’établissement de détention (PERM-)
        ainsi qu’un centre d’hébergement communautaire (CHC) pour des individus
        en libération d’office, qui sans l’encadrement de L’Issue demeureraient
        en détention. Elle est pour eux une occasion de s’engager dans une
        démarche constructive et de démontrer aux autorités leur potentiel de
        réinsertion sociale en travaillant en profondeur les motifs sous-jacent
        à leurs comportements délinquants afin d’éviter d’avoir recours à la
        criminalité.
      </Paragraph>
      <Paragraph>
        La Maison L’Issue accueille à l’occasion des sentenciés faisant l’objet
        d’une ordonnance de probation avec surveillance ou de sursis
        d’emprisonnement qui nécessite un encadrement quotidien et soutenu.
        Ainsi, pour ces derniers, il s’agit d’une démarche volontaire permettant
        de choisir temporairement un encadrement adapté et une programmation
        exigeante qui vise des résultats positifs.
      </Paragraph>
      <Paragraph>
        La clientèle hébergée à La Maison L’Issue n’a pas une problématique
        unique, même si la ressource offre un programme d’encadrement spécialisé
        en violence conjugale. La Maison L’Issue n’adopte pas d’orientation
        clinique unique, elle s’attaque plutôt à la situation particulière du
        résident. En fonction des objectifs spécifiques à travailler qui sont
        déterminé par le plan d’intervention correctionnel, une orientation
        clinique cohérente est utilisée afin d’accompagner le résident vers
        l’atteinte de ses objectifs. Certaines problématiques spécifiques
        (toxicomanie, santé mentale, délinquance sexuelle…) sont prises en
        charge par des ressources thérapeutiques spécialisées.
      </Paragraph>
      <Paragraph>
        De plus, de par son approche et ses valeurs humaines, les règles de la
        programmation et surtout l’intervention prônée à Transition Centre-Sud
        permettent à la clientèle d’être orientée et évaluée de façon constante
        afin qu’elle sente ce support dont elle a besoin dans
        l’approfondissement de sa réflexion (conscientisation,
        responsabilisation, solutions alternatives) et l’activation des
        démarches reliées à ses problèmes.
      </Paragraph>
      <Image />
    </Container>
  </Content>
);

export default RaisonDetrePage;
