import React from "react";
import styled from "styled-components";
import { Grid, Divider, Container } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Headline = styled.p`
  padding: 1.5em 0;
  text-transform: uppercase;
  color: rgb(86, 86, 86);
  font-size: 1.9em;
  // font-weight: n;
  line-height: 1.5em;
  text-align: center;
`;

const MissionPage = () => (
  <Content>
    <Hero>Mission</Hero>
    <Container text>
      <Grid container centered>
        <Grid.Column width={16}>
          <Headline align="center">
            Offrir à ses clientèles adultes des moyens d'éviter le recours aux
            comportements délinquants
          </Headline>

          <Divider
            as="h4"
            className="header"
            horizontal
            style={{
              margin: "1em 0em",
              fontSize: "1.1em",
              color: "rgb(95,95,95)",
              fontWeight: "normal",
              textTransform: "uppercase"
            }}
          >
            Et / Ou
          </Divider>
          <Headline align="center">
            leur offrir un encadrement substitutif à l'incarcération,
          </Headline>

          <Divider
            as="h4"
            className="header"
            horizontal
            style={{
              margin: "1em 0em",
              fontSize: "1.1em",
              fontWeight: "normal",
              textTransform: "uppercase"
            }}
          >
            Ainsi que
          </Divider>
          <Headline align="center">
            sensibiliser la communauté aux réalités de ces clientèles.
          </Headline>
        </Grid.Column>
      </Grid>
    </Container>
  </Content>
);

export default MissionPage;
