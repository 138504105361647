import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Stages</Hero>
    <Container text>
      <Paragraph>
        Transition Centre-Sud accueille chaque année des étudiants au niveau
        collégial (intervention en délinquance) et universitaire (criminologie).
        Pour plus de renseignements, quant aux possibilités de stage, vous
        pouvez nous contacter.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
