import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Portes ouvertes</Hero>
    <Container text>
      <Paragraph>
        Les portes ouvertes pour la clientèle étant référé à La Passerelle sont
        les mercredis entre 13h et 15h. au 1815 boul. Rosemont, Montréal.
      </Paragraph>

      <Paragraph>
        Si vous ne pouvez être présent au moment inscrit sur votre lettre de
        convocation remise par l’agent de probation, veuillez communiquer avec
        la ressource : 514-270-6633.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
