import React from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: url(/images/immeuble-tcs.jpg) top center no-repeat;
  background-size: cover;
  min-height: 1100px;

  height: calc(100vh - 183px);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  line-height: 1.2em;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  color: #fff;
`;

const Content = styled.div`
  z-index: 3;
`;

const Subtitle = styled.h2`
  margin-top: 0em;
  margin-bottom: 2em;
  font-size: 2.2em;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
  // text-transform: uppercase;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(29, 41, 62, 0.2);
`;

const Page = () => (
  <Wrapper>
    <Overlay />
    <Content>
      <Title>Transition Centre-Sud</Title>
      <Subtitle>un centre de services en délinquance adulte</Subtitle>
      <NavLink to="/a-propos-de-nous/mission">
        <Button color="yellow" size="huge">
          Consulter notre mission
        </Button>
      </NavLink>
    </Content>
  </Wrapper>
);

export default Page;
