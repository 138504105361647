import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Bénévolat</Hero>
    <Container text>
      <Paragraph>
        Transition Centre-Sud se montre intéressé à toute offre de bénévolat
        pouvant faciliter l’intégration dans la communauté de ses usagers. Pour
        plus de renseignements, vous pouvez nous contacter.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
