import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Conseil d’administration</Hero>
    <Container text>
      <Paragraph>
        Transition Centre-Sud est un OSBL chapeauté par un conseil
        d’administration.
      </Paragraph>

      <Paragraph>
        La corporation est administrée par un conseil d’administration composé
        d’au plus douze (12) administrateurs dont un siège est réservé à un
        membre oeuvrant dans le domaine de la délinquance et un siège est
        réservé à un membre ayant une expérience des organismes communautaires
        ou de la vie de quartier. Le directeur de Transition Centre-Sud fait
        partie d’office du conseil d’administration.
      </Paragraph>
      <Paragraph>
        Si vous êtes intéressé à vous impliquer dans le Conseil
        d’administration, vous pouvez nous contacter.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
