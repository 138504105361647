import React from "react";
import styled from "styled-components";

import { Container, Header, Image } from "semantic-ui-react";
import { Paragraph, Hero, Content } from "../../layout";

const Page = () => (
  <Content>
    <Hero>Centre résidentiel communautaire</Hero>
    <Container text>
      <Paragraph>
        La Maison L’Issue est un centre résidentiel communautaire (CRC) pour les
        individus en permission de sortie préparatoire à la libération
        conditionnelle ou en libération conditionnelle ainsi qu’un centre
        d’hébergement communautaire (CHC) pour des individus en libération
        d’office, qui sans l’encadrement de L’Issue demeureraient en détention.
        Elle est pour eux une occasion de s’engager dans une démarche
        constructive et de démontrer aux autorités leur potentiel de réinsertion
        sociale en travaillant en profondeur les motifs sous-jacent à leurs
        comportements délinquants afin d’éviter d’avoir recours à la
        criminalité.
      </Paragraph>

      <Paragraph>
        La Maison L’Issue accueille à l’occasion des sentenciés faisant l’objet
        d’une ordonnance de probation avec surveillance ou de sursis
        d’emprisonnement qui nécessite un encadrement quotidien et soutenu.
        Ainsi, pour ces derniers, il s’agit d’une démarche volontaire permettant
        de choisir temporairement un encadrement adapté et une programmation
        exigeante qui vise des résultats positifs.
      </Paragraph>

      <Paragraph>
        De plus, La Maison L’Issue offre trois programmes spécifiques, soit :
        <ul>
          <li>
            Le programme Point Final. Un programme spécialisé pour les
            sentenciés ayant commis des gestes en matière de violence conjugale.
          </li>
          <li>
            Le programme court : Un programme spécialisé pour les contrevenants
            ayant une sentence de détention de moins de 6 mois.
          </li>
          <li>
            Le centre d’hébergement communautaire : Un service d’hébergement
            avec encadrement offert à une clientèle fédérale.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        De façon générale, La Maison L’Issue vise à outiller le résident afin
        qu’il évite d’avoir recours à la criminalité.
      </Paragraph>

      <Paragraph>
        C’est en faisant de la prévention de la récidive la base de notre
        intervention qu’on aborde le séjour du résident à La Maison L’Issue.
        Cette notion inclut également les concepts de : gestion du risque,
        réhabilitation, réinsertion sociale, éducation, responsabilisation,
        développement d’habiletés sociales, atteinte de l’autonomie, etc…{" "}
      </Paragraph>
      <Paragraph>
        À l’aide des outils disponibles (LS/CMI ou SOIE, évaluation d’admission,
        expertise…), les professionnels de La Maison L’Issue élaborent une
        stratégie d’intervention individualisée. Plus particulièrement, La
        Maison L’Issue vise à ce que le résident entame une prise de conscience
        de sa situation et qu’il travaille activement à l’atteinte de ses
        objectifs. En effet, pour démarrer un travail efficace et modifier des
        facettes de sa vie, le résident doit avant tout prendre conscience de sa
        situation légale, et surtout, prendre conscience des problèmes qui l’ont
        amené jusqu’à nous et des aspects à travailler.
      </Paragraph>
      <Paragraph>
        On s’attend à ce que chaque résident s’engage dans une démarche
        d’activation et de responsabilisation en lien avec ses choix, ses
        actions et les problématiques ayant précipité son agir délictuel. Tel
        que mentionné plus haut, il est le principal acteur de son cheminement.
        Les professionnels de La Maison L’Issue ne peuvent que l’orienter,
        l’encadrer, l’accompagner et le supporter dans ses décisions et
        démarches.
      </Paragraph>
      <Paragraph>
        Durant son séjour à La Maison L’Issue, le résident sera invité à faire
        le tri de ses fréquentations et à développer un réseau social positif,
        présent et supportant.
      </Paragraph>
    </Container>
  </Content>
);

export default Page;
